import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { SamplePageComponent } from './demo/pages/sample-page/sample-page.component';
import { LoggedGuard } from './services/logged.guard';

const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        canActivate: [LoggedGuard],
        children: [
            {
                path: '',
                redirectTo: 'sample-page',
                pathMatch: 'full',
            },
            {
                path: 'sample-page',
                component: SamplePageComponent
            },
            {
                path: 'CategoryFatigueStatus',
                loadChildren: () => import('./module/category-fatigue-status/category-fatigue-status.module')
                    .then(m => m.CategoryFatigueStatusModule)
            },
            {
                path: 'categoryProductAndService',
                loadChildren: () => import('./module/category-product-and-service/category-product-and-service.module')
                    .then(m => m.CategoryProductAndServiceModule)
            },
            {
                path: 'Cuen_Det',
                loadChildren: () => import('./module/cuen-det/cuen-det.module')
                    .then(m => m.Cuen_DetModule)
            },
            {
                path: 'Cuen_Mo',
                loadChildren: () => import('./module/cuen-mo/cuen-mo.module')
                    .then(m => m.Cuen_MoModule)
            },
            {
                path: 'FactD',
                loadChildren: () => import('./module/fact-d/fact-d.module')
                    .then(m => m.FactDModule)
            },
            {
                path: 'FactF',
                loadChildren: () => import('./module/fact-f/fact-f.module')
                    .then(m => m.FactFModule)
            },
            {
                path: 'Par_Facd',
                loadChildren: () => import('./module/par-facd/par-facd.module')
                    .then(m => m.Par_FacdModule)
            },
            {
                path: 'Par_Fact',
                loadChildren: () => import('./module/par-fact/par-fact.module')
                    .then(m => m.Par_FactModule)
            },
            {
                path: 'accountCategory',
                loadChildren: () => import('./module/account-category/account-category.module')
                    .then(m => m.accountCategoryModule)
            },
            {
                path: 'accountStatus',
                loadChildren: () => import('./module/account-status/account-status.module')
                    .then(m => m.accountStatusModule)
            },
            {
                path: 'accountType',
                loadChildren: () => import('./module/account-type/account-type.module')
                    .then(m => m.accountTypeModule)
            },
            {
                path: 'accountingAcount',
                loadChildren: () => import('./module/accounting-acount/accounting-acount.module')
                    .then(m => m.accountingAcountModule)
            },
            {
                path: 'accountingBeneficiary',
                loadChildren: () => import('./module/accounting-beneficiary/accounting-beneficiary.module')
                    .then(m => m.accountingBeneficiaryModule)
            },
            {
                path: 'acount',
                loadChildren: () => import('./module/acount/acount.module')
                    .then(m => m.acountModule)
            },
            {
                path: 'acountUser',
                loadChildren: () => import('./module/acount-user/acount-user.module')
                    .then(m => m.acountUserModule)
            },
            {
                path: 'bank',
                loadChildren: () => import('./module/bank/bank.module')
                    .then(m => m.bankModule)
            },
            {
                path: 'bankAccountEnterprise',
                loadChildren: () => import('./module/bank-account-enterprise/bank-account-enterprise.module')
                    .then(m => m.bankAccountEnterpriseModule)
            },
            {
                path: 'bankAcount',
                loadChildren: () => import('./module/bank-acount/bank-acount.module')
                    .then(m => m.bankAcountModule)
            },
            {
                path: 'bankEmployee',
                loadChildren: () => import('./module/bank-employee/bank-employee.module')
                    .then(m => m.bankEmployeeModule)
            },
            {
                path: 'binnacle',
                loadChildren: () => import('./module/binnacle/binnacle.module')
                    .then(m => m.binnacleModule)
            },
            {
                path: 'bloodType',
                loadChildren: () => import('./module/blood-type/blood-type.module')
                    .then(m => m.bloodTypeModule)
            },
            {
                path: 'branchOffice',
                loadChildren: () => import('./module/branch-office/branch-office.module')
                    .then(m => m.branchOfficeModule)
            },
            {
                path: 'budgetOffice',
                loadChildren: () => import('./module/budget-office/budget-office.module')
                    .then(m => m.budgetOfficeModule)
            },
            {
                path: 'budgetTransfer',
                loadChildren: () => import('./module/budget-transfer/budget-transfer.module')
                    .then(m => m.budgetTransferModule)
            },
            {
                path: 'businessUnit',
                loadChildren: () => import('./module/business-unit/business-unit.module')
                    .then(m => m.businessUnitModule)
            },
            {
                path: 'catalogOfProductsAndServices',
                loadChildren: () => import('./module/catalog-of-products-and-services/catalog-of-products-and-services.module')
                    .then(m => m.catalogOfProductsAndServicesModule)
            },
            {
                path: 'categoryEquipment',
                loadChildren: () => import('./module/category-equipment/category-equipment.module')
                    .then(m => m.categoryEquipmentModule)
            },
            {
                path: 'civilState',
                loadChildren: () => import('./module/civil-state/civil-state.module')
                    .then(m => m.civilStateModule)
            },
            {
                path: 'comercialCondition',
                loadChildren: () => import('./module/comercial-condition/comercial-condition.module')
                    .then(m => m.comercialConditionModule)
            },
            {
                path: 'commercialCondition',
                loadChildren: () => import('./module/commercial-condition/commercial-condition.module')
                    .then(m => m.commercialConditionModule)
            },
            {
                path: 'commercialConditionProvider',
                loadChildren: () => import('./module/commercial-condition-provider/commercial-condition-provider.module')
                    .then(m => m.commercialConditionProviderModule)
            },
            {
                path: 'contableAcount',
                loadChildren: () => import('./module/contable-acount/contable-acount.module')
                    .then(m => m.contableAcountModule)
            },
            {
                path: 'contableAcountType',
                loadChildren: () => import('./module/contable-acount-type/contable-acount-type.module')
                    .then(m => m.contableAcountTypeModule)
            },
            {
                path: 'costCenter',
                loadChildren: () => import('./module/cost-center/cost-center.module')
                    .then(m => m.costCenterModule)
            },
            {
                path: 'creditDays',
                loadChildren: () => import('./module/credit-days/credit-days.module')
                    .then(m => m.creditDaysModule)
            },
            {
                path: 'currencyCatalog',
                loadChildren: () => import('./module/currency-catalog/currency-catalog.module')
                    .then(m => m.currencyCatalogModule)
            },
            {
                path: 'customer',
                loadChildren: () => import('./module/customer/customer.module')
                    .then(m => m.customerModule)
            },
            {
                path: 'customerDocument',
                loadChildren: () => import('./module/customer-document/customer-document.module')
                    .then(m => m.customerDocumentModule)
            },
            {
                path: 'customerDocumentCatalog',
                loadChildren: () => import('./module/customer-document-catalog/customer-document-catalog.module')
                    .then(m => m.customerDocumentCatalogModule)
            },
            {
                path: 'customerRequirements',
                loadChildren: () => import('./module/customer-requirements/customer-requirements.module')
                    .then(m => m.customerRequirementsModule)
            },
            {
                path: 'customerRequirementsEquipment',
                loadChildren: () => import('./module/customer-requirements-equipment/customer-requirements-equipment.module')
                    .then(m => m.customerRequirementsEquipmentModule)
            },
            {
                path: 'customerRequirementsPerson',
                loadChildren: () => import('./module/customer-requirements-person/customer-requirements-person.module')
                    .then(m => m.customerRequirementsPersonModule)
            },
            {
                path: 'customerRequirementsServices',
                loadChildren: () => import('./module/customer-requirements-services/customer-requirements-services.module')
                    .then(m => m.customerRequirementsServicesModule)
            },
            {
                path: 'customerTemplate',
                loadChildren: () => import('./module/customer-template/customer-template.module')
                    .then(m => m.customerTemplateModule)
            },
            {
                path: 'customerZone',
                loadChildren: () => import('./module/customer-zone/customer-zone.module')
                    .then(m => m.customerZoneModule)
            },
            {
                path: 'daysCoverage',
                loadChildren: () => import('./module/days-coverage/days-coverage.module')
                    .then(m => m.daysCoverageModule)
            },
            {
                path: 'discountFactor',
                loadChildren: () => import('./module/discount-factor/discount-factor.module')
                    .then(m => m.discountFactorModule)
            },
            {
                path: 'documentCatalogEmployee',
                loadChildren: () => import('./module/document-catalog-employee/document-catalog-employee.module')
                    .then(m => m.documentCatalogEmployeeModule)
            },
            {
                path: 'documentEmployeeCategory',
                loadChildren: () => import('./module/document-employee-category/document-employee-category.module')
                    .then(m => m.documentEmployeeCategoryModule)
            },
            {
                path: 'documentsCatalog',
                loadChildren: () => import('./module/documents-catalog/documents-catalog.module')
                    .then(m => m.documentsCatalogModule)
            },
            {
                path: 'documentsCatalogprovider',
                loadChildren: () => import('./module/documents-catalogprovider/documents-catalogprovider.module')
                    .then(m => m.documentsCatalogproviderModule)
            },
            {
                path: 'employeDisability',
                loadChildren: () => import('./module/employe-disability/employe-disability.module')
                    .then(m => m.employeDisabilityModule)
            },
            {
                path: 'employee',
                loadChildren: () => import('./module/employee/employee.module')
                    .then(m => m.employeeModule)
            },
            {
                path: 'employeeCourses',
                loadChildren: () => import('./module/employee-courses/employee-courses.module')
                    .then(m => m.employeeCoursesModule)
            },
            {
                path: 'employeeDiscountFactor',
                loadChildren: () => import('./module/employee-discount-factor/employee-discount-factor.module')
                    .then(m => m.employeeDiscountFactorModule)
            },
            {
                path: 'employeeDiscountFoancot',
                loadChildren: () => import('./module/employee-discount-foancot/employee-discount-foancot.module')
                    .then(m => m.employeeDiscountFoancotModule)
            },
            {
                path: 'employeeDocument',
                loadChildren: () => import('./module/employee-document/employee-document.module')
                    .then(m => m.employeeDocumentModule)
            },
            {
                path: 'employeeIdioms',
                loadChildren: () => import('./module/employee-idioms/employee-idioms.module')
                    .then(m => m.employeeIdiomsModule)
            },
            {
                path: 'employeeLocation',
                loadChildren: () => import('./module/employee-location/employee-location.module')
                    .then(m => m.employeeLocationModule)
            },
            {
                path: 'employeePsychometricTest',
                loadChildren: () => import('./module/employee-psychometric-test/employee-psychometric-test.module')
                    .then(m => m.employeePsychometricTestModule)
            },
            {
                path: 'employeeScholarship',
                loadChildren: () => import('./module/employee-scholarship/employee-scholarship.module')
                    .then(m => m.employeeScholarshipModule)
            },
            {
                path: 'employeeStatus',
                loadChildren: () => import('./module/employee-status/employee-status.module')
                    .then(m => m.employeeStatusModule)
            },
            {
                path: 'enterprise',
                loadChildren: () => import('./module/enterprise/enterprise.module')
                    .then(m => m.enterpriseModule)
            },
            {
                path: 'equipment',
                loadChildren: () => import('./module/equipment/equipment.module')
                    .then(m => m.equipmentModule)
            },
            {
                path: 'equipmentInventory',
                loadChildren: () => import('./module/equipment-inventory/equipment-inventory.module')
                    .then(m => m.equipmentInventoryModule)
            },
            {
                path: 'equipmentSupplier',
                loadChildren: () => import('./module/equipment-supplier/equipment-supplier.module')
                    .then(m => m.equipmentSupplierModule)
            },
            {
                path: 'equivalentUnits',
                loadChildren: () => import('./module/equivalent-units/equivalent-units.module')
                    .then(m => m.equivalentUnitsModule)
            },
            {
                path: 'estate',
                loadChildren: () => import('./module/estate/estate.module')
                    .then(m => m.estateModule)
            },
            {
                path: 'excludeConceptOffice',
                loadChildren: () => import('./module/exclude-concept-office/exclude-concept-office.module')
                    .then(m => m.excludeConceptOfficeModule)
            },
            {
                path: 'expenseClassification',
                loadChildren: () => import('./module/expense-classification/expense-classification.module')
                    .then(m => m.expenseClassificationModule)
            },
            {
                path: 'expenseConcept',
                loadChildren: () => import('./module/expense-concept/expense-concept.module')
                    .then(m => m.expenseConceptModule)
            },
            {
                path: 'expenseConceptClassification',
                loadChildren: () => import('./module/expense-concept-classification/expense-concept-classification.module')
                    .then(m => m.expenseConceptClassificationModule)
            },
            {
                path: 'expenseType',
                loadChildren: () => import('./module/expense-type/expense-type.module')
                    .then(m => m.expenseTypeModule)
            },
            {
                path: 'fatigue',
                loadChildren: () => import('./module/fatigue/fatigue.module')
                    .then(m => m.fatigueModule)
            },
            {
                path: 'fatigueStatus',
                loadChildren: () => import('./module/fatigue-status/fatigue-status.module')
                    .then(m => m.fatigueStatusModule)
            },
            {
                path: 'federativeEntity',
                loadChildren: () => import('./module/federative-entity/federative-entity.module')
                    .then(m => m.federativeEntityModule)
            },
            {
                path: 'fiscalData',
                loadChildren: () => import('./module/fiscal-data/fiscal-data.module')
                    .then(m => m.fiscalDataModule)
            },
            {
                path: 'fiscalRegime',
                loadChildren: () => import('./module/fiscal-regime/fiscal-regime.module')
                    .then(m => m.fiscalRegimeModule)
            },
            {
                path: 'formQuery',
                loadChildren: () => import('./module/form-query/form-query.module')
                    .then(m => m.formQueryModule)
            },
            {
                path: 'fortnight',
                loadChildren: () => import('./module/fortnight/fortnight.module')
                    .then(m => m.fortnightModule)
            },
            {
                path: 'gambling',
                loadChildren: () => import('./module/gambling/gambling.module')
                    .then(m => m.gamblingModule)
            },
            {
                path: 'gestionOffice',
                loadChildren: () => import('./module/gestion-office/gestion-office.module')
                    .then(m => m.gestionOfficeModule)
            },
            {
                path: 'gestionType',
                loadChildren: () => import('./module/gestion-type/gestion-type.module')
                    .then(m => m.gestionTypeModule)
            },
            {
                path: 'historyWorkForce',
                loadChildren: () => import('./module/history-work-force/history-work-force.module')
                    .then(m => m.historyWorkForceModule)
            },
            {
                path: 'idioms',
                loadChildren: () => import('./module/idioms/idioms.module')
                    .then(m => m.idiomsModule)
            },
            {
                path: 'idiomsLevel',
                loadChildren: () => import('./module/idioms-level/idioms-level.module')
                    .then(m => m.idiomsLevelModule)
            },
            {
                path: 'imss',
                loadChildren: () => import('./module/imss/imss.module')
                    .then(m => m.imssModule)
            },
            {
                path: 'imssStatus',
                loadChildren: () => import('./module/imss-status/imss-status.module')
                    .then(m => m.imssStatusModule)
            },
            {
                path: 'incident',
                loadChildren: () => import('./module/incident/incident.module')
                    .then(m => m.incidentModule)
            },
            {
                path: 'incidentCatalog',
                loadChildren: () => import('./module/incident-catalog/incident-catalog.module')
                    .then(m => m.incidentCatalogModule)
            },
            {
                path: 'income',
                loadChildren: () => import('./module/income/income.module')
                    .then(m => m.incomeModule)
            },
            {
                path: 'incomeDetail',
                loadChildren: () => import('./module/income-detail/income-detail.module')
                    .then(m => m.incomeDetailModule)
            },
            {
                path: 'incomeReason',
                loadChildren: () => import('./module/income-reason/income-reason.module')
                    .then(m => m.incomeReasonModule)
            },
            {
                path: 'installationExist',
                loadChildren: () => import('./module/installation-exist/installation-exist.module')
                    .then(m => m.installationExistModule)
            },
            {
                path: 'installationExistCatalog',
                loadChildren: () => import('./module/installation-exist-catalog/installation-exist-catalog.module')
                    .then(m => m.installationExistCatalogModule)
            },
            {
                path: 'invoicePeriod',
                loadChildren: () => import('./module/invoice-period/invoice-period.module')
                    .then(m => m.invoicePeriodModule)
            },
            {
                path: 'invoicedEquipment',
                loadChildren: () => import('./module/invoiced-equipment/invoiced-equipment.module')
                    .then(m => m.invoicedEquipmentModule)
            },
            {
                path: 'invoicedResource',
                loadChildren: () => import('./module/invoiced-resource/invoiced-resource.module')
                    .then(m => m.invoicedResourceModule)
            },
            {
                path: 'jobProfile',
                loadChildren: () => import('./module/job-profile/job-profile.module')
                    .then(m => m.jobProfileModule)
            },
            {
                path: 'licenseType',
                loadChildren: () => import('./module/license-type/license-type.module')
                    .then(m => m.licenseTypeModule)
            },
            {
                path: 'liquidationPercentage',
                loadChildren: () => import('./module/liquidation-percentage/liquidation-percentage.module')
                    .then(m => m.liquidationPercentageModule)
            },
            {
                path: 'loan',
                loadChildren: () => import('./module/loan/loan.module')
                    .then(m => m.loanModule)
            },
            {
                path: 'loanPays',
                loadChildren: () => import('./module/loan-pays/loan-pays.module')
                    .then(m => m.loanPaysModule)
            },
            {
                path: 'loanStatus',
                loadChildren: () => import('./module/loan-status/loan-status.module')
                    .then(m => m.loanStatusModule)
            },
            {
                path: 'location',
                loadChildren: () => import('./module/location/location.module')
                    .then(m => m.locationModule)
            },
            {
                path: 'locationVehicleStatus',
                loadChildren: () => import('./module/location-vehicle-status/location-vehicle-status.module')
                    .then(m => m.locationVehicleStatusModule)
            },
            {
                path: 'lotImss',
                loadChildren: () => import('./module/lot-imss/lot-imss.module')
                    .then(m => m.lotImssModule)
            },
            {
                path: 'mainOffice',
                loadChildren: () => import('./module/main-office/main-office.module')
                    .then(m => m.mainOfficeModule)
            },
            {
                path: 'manageCompany',
                loadChildren: () => import('./module/manage-company/manage-company.module')
                    .then(m => m.manageCompanyModule)
            },
            {
                path: 'manageCompanyType',
                loadChildren: () => import('./module/manage-company-type/manage-company-type.module')
                    .then(m => m.manageCompanyTypeModule)
            },
            {
                path: 'managingBank',
                loadChildren: () => import('./module/managing-bank/managing-bank.module')
                    .then(m => m.managingBankModule)
            },
            {
                path: 'month',
                loadChildren: () => import('./module/month/month.module')
                    .then(m => m.monthModule)
            },
            {
                path: 'monthlyCut',
                loadChildren: () => import('./module/monthly-cut/monthly-cut.module')
                    .then(m => m.monthlyCutModule)
            },
            {
                path: 'movementTypeImss',
                loadChildren: () => import('./module/movement-type-imss/movement-type-imss.module')
                    .then(m => m.movementTypeImssModule)
            },
            {
                path: 'notification',
                loadChildren: () => import('./module/notification/notification.module')
                    .then(m => m.notificationModule)
            },
            {
                path: 'notificationCatalog',
                loadChildren: () => import('./module/notification-catalog/notification-catalog.module')
                    .then(m => m.notificationCatalogModule)
            },
            {
                path: 'notificationIncident',
                loadChildren: () => import('./module/notification-incident/notification-incident.module')
                    .then(m => m.notificationIncidentModule)
            },
            {
                path: 'notificationRequirements',
                loadChildren: () => import('./module/notification-requirements/notification-requirements.module')
                    .then(m => m.notificationRequirementsModule)
            },
            {
                path: 'notificationRequirementsEquipment',
                loadChildren: () => import('./module/notification-requirements-equipment/notification-requirements-equipment.module')
                    .then(m => m.notificationRequirementsEquipmentModule)
            },
            {
                path: 'notificationRequirementsIncident',
                loadChildren: () => import('./module/notification-requirements-incident/notification-requirements-incident.module')
                    .then(m => m.notificationRequirementsIncidentModule)
            },
            {
                path: 'notificationRequirementsPerson',
                loadChildren: () => import('./module/notification-requirements-person/notification-requirements-person.module')
                    .then(m => m.notificationRequirementsPersonModule)
            },
            {
                path: 'notificationRequirementsServices',
                loadChildren: () => import('./module/notification-requirements-services/notification-requirements-services.module')
                    .then(m => m.notificationRequirementsServicesModule)
            },
            {
                path: 'office',
                loadChildren: () => import('./module/office/office.module')
                    .then(m => m.officeModule)
            },
            {
                path: 'officeManageCompany',
                loadChildren: () => import('./module/office-manage-company/office-manage-company.module')
                    .then(m => m.officeManageCompanyModule)
            },
            {
                path: 'officeResponsable',
                loadChildren: () => import('./module/office-responsable/office-responsable.module')
                    .then(m => m.officeResponsableModule)
            },
            {
                path: 'optionCatalog',
                loadChildren: () => import('./module/option-catalog/option-catalog.module')
                    .then(m => m.optionCatalogModule)
            },
            {
                path: 'optionCatalogValue',
                loadChildren: () => import('./module/option-catalog-value/option-catalog-value.module')
                    .then(m => m.optionCatalogValueModule)
            },
            {
                path: 'orderMaintenance',
                loadChildren: () => import('./module/order-maintenance/order-maintenance.module')
                    .then(m => m.orderMaintenanceModule)
            },
            {
                path: 'orderOption',
                loadChildren: () => import('./module/order-option/order-option.module')
                    .then(m => m.orderOptionModule)
            },
            {
                path: 'packProductAndService',
                loadChildren: () => import('./module/pack-product-and-service/pack-product-and-service.module')
                    .then(m => m.packProductAndServiceModule)
            },
            {
                path: 'payRollDetailPerDed',
                loadChildren: () => import('./module/pay-roll-detail-per-ded/pay-roll-detail-per-ded.module')
                    .then(m => m.payRollDetailPerDedModule)
            },
            {
                path: 'paymentFormat',
                loadChildren: () => import('./module/payment-format/payment-format.module')
                    .then(m => m.paymentFormatModule)
            },
            {
                path: 'paymentMethod',
                loadChildren: () => import('./module/payment-method/payment-method.module')
                    .then(m => m.paymentMethodModule)
            },
            {
                path: 'paymentRequest',
                loadChildren: () => import('./module/payment-request/payment-request.module')
                    .then(m => m.paymentRequestModule)
            },
            {
                path: 'paymentRequestDetail',
                loadChildren: () => import('./module/payment-request-detail/payment-request-detail.module')
                    .then(m => m.paymentRequestDetailModule)
            },
            {
                path: 'paymentRequestStatus',
                loadChildren: () => import('./module/payment-request-status/payment-request-status.module')
                    .then(m => m.paymentRequestStatusModule)
            },
            {
                path: 'paymentRequestType',
                loadChildren: () => import('./module/payment-request-type/payment-request-type.module')
                    .then(m => m.paymentRequestTypeModule)
            },
            {
                path: 'paymentType',
                loadChildren: () => import('./module/payment-type/payment-type.module')
                    .then(m => m.paymentTypeModule)
            },
            {
                path: 'payroll',
                loadChildren: () => import('./module/payroll/payroll.module')
                    .then(m => m.payrollModule)
            },
            {
                path: 'payrollDates',
                loadChildren: () => import('./module/payroll-dates/payroll-dates.module')
                    .then(m => m.payrollDatesModule)
            },
            {
                path: 'payrollDetail',
                loadChildren: () => import('./module/payroll-detail/payroll-detail.module')
                    .then(m => m.payrollDetailModule)
            },
            {
                path: 'payrollSDP',
                loadChildren: () => import('./module/payroll-s-d-p/payroll-s-d-p.module')
                    .then(m => m.payrollSDPModule)
            },
            {
                path: 'perceptionDeduction',
                loadChildren: () => import('./module/perception-deduction/perception-deduction.module')
                    .then(m => m.perceptionDeductionModule)
            },
            {
                path: 'pipeLine',
                loadChildren: () => import('./module/pipe-line/pipe-line.module')
                    .then(m => m.pipeLineModule)
            },
            {
                path: 'pipeLineCatalog',
                loadChildren: () => import('./module/pipe-line-catalog/pipe-line-catalog.module')
                    .then(m => m.pipeLineCatalogModule)
            },
            {
                path: 'pipeLineLog',
                loadChildren: () => import('./module/pipe-line-log/pipe-line-log.module')
                    .then(m => m.pipeLineLogModule)
            },
            {
                path: 'pipeLinePercentage',
                loadChildren: () => import('./module/pipe-line-percentage/pipe-line-percentage.module')
                    .then(m => m.pipeLinePercentageModule)
            },
            {
                path: 'poll',
                loadChildren: () => import('./module/poll/poll.module')
                    .then(m => m.pollModule)
            },
            {
                path: 'postalCode',
                loadChildren: () => import('./module/postal-code/postal-code.module')
                    .then(m => m.postalCodeModule)
            },
            {
                path: 'process',
                loadChildren: () => import('./module/process/process.module')
                    .then(m => m.processModule)
            },
            {
                path: 'productAndService',
                loadChildren: () => import('./module/product-and-service/product-and-service.module')
                    .then(m => m.productAndServiceModule)
            },
            {
                path: 'productAndServiceComercialCondition',
                loadChildren: () => import('./module/product-and-service-comercial-condition/product-and-service-comercial-condition.module')
                    .then(m => m.productAndServiceComercialConditionModule)
            },
            {
                path: 'productOption',
                loadChildren: () => import('./module/product-option/product-option.module')
                    .then(m => m.productOptionModule)
            },
            {
                path: 'productOptionValue',
                loadChildren: () => import('./module/product-option-value/product-option-value.module')
                    .then(m => m.productOptionValueModule)
            },
            {
                path: 'productsAndServices',
                loadChildren: () => import('./module/products-and-services/products-and-services.module')
                    .then(m => m.productsAndServicesModule)
            },
            {
                path: 'productsAndServicesAttribute',
                loadChildren: () => import('./module/products-and-services-attribute/products-and-services-attribute.module')
                    .then(m => m.productsAndServicesAttributeModule)
            },
            {
                path: 'productsTaxes',
                loadChildren: () => import('./module/products-taxes/products-taxes.module')
                    .then(m => m.productsTaxesModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('./module/profile/profile.module')
                    .then(m => m.profileModule)
            },
            {
                path: 'propertyType',
                loadChildren: () => import('./module/property-type/property-type.module')
                    .then(m => m.propertyTypeModule)
            },
            {
                path: 'propossalType',
                loadChildren: () => import('./module/propossal-type/propossal-type.module')
                    .then(m => m.propossalTypeModule)
            },
            {
                path: 'prospectStatus',
                loadChildren: () => import('./module/prospect-status/prospect-status.module')
                    .then(m => m.prospectStatusModule)
            },
            {
                path: 'provider',
                loadChildren: () => import('./module/provider/provider.module')
                    .then(m => m.providerModule)
            },
            {
                path: 'psychometricTest',
                loadChildren: () => import('./module/psychometric-test/psychometric-test.module')
                    .then(m => m.psychometricTestModule)
            },
            {
                path: 'purchaseOrder',
                loadChildren: () => import('./module/purchase-order/purchase-order.module')
                    .then(m => m.purchaseOrderModule)
            },
            {
                path: 'purchaseOrderComment',
                loadChildren: () => import('./module/purchase-order-comment/purchase-order-comment.module')
                    .then(m => m.purchaseOrderCommentModule)
            },
            {
                path: 'purchaseOrderDetail',
                loadChildren: () => import('./module/purchase-order-detail/purchase-order-detail.module')
                    .then(m => m.purchaseOrderDetailModule)
            },
            {
                path: 'purchaseOrderTaxes',
                loadChildren: () => import('./module/purchase-order-taxes/purchase-order-taxes.module')
                    .then(m => m.purchaseOrderTaxesModule)
            },
            {
                path: 'purchaseStatus',
                loadChildren: () => import('./module/purchase-status/purchase-status.module')
                    .then(m => m.purchaseStatusModule)
            },
            {
                path: 'quotation',
                loadChildren: () => import('./module/quotation/quotation.module')
                    .then(m => m.quotationModule)
            },
            {
                path: 'quotationDetail',
                loadChildren: () => import('./module/quotation-detail/quotation-detail.module')
                    .then(m => m.quotationDetailModule)
            },
            {
                path: 'recurring',
                loadChildren: () => import('./module/recurring/recurring.module')
                    .then(m => m.recurringModule)
            },
            {
                path: 'reportTemplate',
                loadChildren: () => import('./module/report-template/report-template.module')
                    .then(m => m.reportTemplateModule)
            },
            {
                path: 'reportTypeTemplate',
                loadChildren: () => import('./module/report-type-template/report-type-template.module')
                    .then(m => m.reportTypeTemplateModule)
            },
            {
                path: 'requisition',
                loadChildren: () => import('./module/requisition/requisition.module')
                    .then(m => m.requisitionModule)
            },
            {
                path: 'requisitionComment',
                loadChildren: () => import('./module/requisition-comment/requisition-comment.module')
                    .then(m => m.requisitionCommentModule)
            },
            {
                path: 'requisitionDetail',
                loadChildren: () => import('./module/requisition-detail/requisition-detail.module')
                    .then(m => m.requisitionDetailModule)
            },
            {
                path: 'requisitionSupply',
                loadChildren: () => import('./module/requisition-supply/requisition-supply.module')
                    .then(m => m.requisitionSupplyModule)
            },
            {
                path: 'scholarship',
                loadChildren: () => import('./module/scholarship/scholarship.module')
                    .then(m => m.scholarshipModule)
            },
            {
                path: 'scholarshipLevel',
                loadChildren: () => import('./module/scholarship-level/scholarship-level.module')
                    .then(m => m.scholarshipLevelModule)
            },
            {
                path: 'sdi',
                loadChildren: () => import('./module/sdi/sdi.module')
                    .then(m => m.sdiModule)
            },
            {
                path: 'service',
                loadChildren: () => import('./module/service/service.module')
                    .then(m => m.serviceModule)
            },
            {
                path: 'serviceCatalog',
                loadChildren: () => import('./module/service-catalog/service-catalog.module')
                    .then(m => m.serviceCatalogModule)
            },
            {
                path: 'serviceEquipmen',
                loadChildren: () => import('./module/service-equipmen/service-equipmen.module')
                    .then(m => m.serviceEquipmenModule)
            },
            {
                path: 'serviceEquipmenCut',
                loadChildren: () => import('./module/service-equipmen-cut/service-equipmen-cut.module')
                    .then(m => m.serviceEquipmenCutModule)
            },
            {
                path: 'serviceEquipment',
                loadChildren: () => import('./module/service-equipment/service-equipment.module')
                    .then(m => m.serviceEquipmentModule)
            },
            {
                path: 'serviceFiscalData',
                loadChildren: () => import('./module/service-fiscal-data/service-fiscal-data.module')
                    .then(m => m.serviceFiscalDataModule)
            },
            {
                path: 'serviceLocation',
                loadChildren: () => import('./module/service-location/service-location.module')
                    .then(m => m.serviceLocationModule)
            },
            {
                path: 'serviceOffice',
                loadChildren: () => import('./module/service-office/service-office.module')
                    .then(m => m.serviceOfficeModule)
            },
            {
                path: 'servicePerson',
                loadChildren: () => import('./module/service-person/service-person.module')
                    .then(m => m.servicePersonModule)
            },
            {
                path: 'servicePersonCut',
                loadChildren: () => import('./module/service-person-cut/service-person-cut.module')
                    .then(m => m.servicePersonCutModule)
            },
            {
                path: 'serviceServices',
                loadChildren: () => import('./module/service-services/service-services.module')
                    .then(m => m.serviceServicesModule)
            },
            {
                path: 'serviceServicesCut',
                loadChildren: () => import('./module/service-services-cut/service-services-cut.module')
                    .then(m => m.serviceServicesCutModule)
            },
            {
                path: 'sex',
                loadChildren: () => import('./module/sex/sex.module')
                    .then(m => m.sexModule)
            },
            {
                path: 'specialCourses',
                loadChildren: () => import('./module/special-courses/special-courses.module')
                    .then(m => m.specialCoursesModule)
            },
            {
                path: 'specialCoursesLevel',
                loadChildren: () => import('./module/special-courses-level/special-courses-level.module')
                    .then(m => m.specialCoursesLevelModule)
            },
            {
                path: 'statuMaintenance',
                loadChildren: () => import('./module/statu-maintenance/statu-maintenance.module')
                    .then(m => m.statuMaintenanceModule)
            },
            {
                path: 'statusBudgetTranfer',
                loadChildren: () => import('./module/status-budget-tranfer/status-budget-tranfer.module')
                    .then(m => m.statusBudgetTranferModule)
            },
            {
                path: 'statusLiquidation',
                loadChildren: () => import('./module/status-liquidation/status-liquidation.module')
                    .then(m => m.statusLiquidationModule)
            },
            {
                path: 'statusLoanPays',
                loadChildren: () => import('./module/status-loan-pays/status-loan-pays.module')
                    .then(m => m.statusLoanPaysModule)
            },
            {
                path: 'statusPayroll',
                loadChildren: () => import('./module/status-payroll/status-payroll.module')
                    .then(m => m.statusPayrollModule)
            },
            {
                path: 'studyStatus',
                loadChildren: () => import('./module/study-status/study-status.module')
                    .then(m => m.studyStatusModule)
            },
            {
                path: 'suaReport',
                loadChildren: () => import('./module/sua-report/sua-report.module')
                    .then(m => m.suaReportModule)
            },
            {
                path: 'systemAction',
                loadChildren: () => import('./module/system-action/system-action.module')
                    .then(m => m.systemActionModule)
            },
            {
                path: 'systemConfig',
                loadChildren: () => import('./module/system-config/system-config.module')
                    .then(m => m.systemConfigModule)
            },
            {
                path: 'systemIcon',
                loadChildren: () => import('./module/system-icon/system-icon.module')
                    .then(m => m.systemIconModule)
            },
            {
                path: 'systemLog',
                loadChildren: () => import('./module/system-log/system-log.module')
                    .then(m => m.systemLogModule)
            },
            {
                path: 'systemMenu',
                loadChildren: () => import('./module/system-menu/system-menu.module')
                    .then(m => m.systemMenuModule)
            },
            {
                path: 'systemPrivileges',
                loadChildren: () => import('./module/system-privileges/system-privileges.module')
                    .then(m => m.systemPrivilegesModule)
            },
            {
                path: 'systemPrivilegesUserRole',
                loadChildren: () => import('./module/system-privileges-user-role/system-privileges-user-role.module')
                    .then(m => m.systemPrivilegesUserRoleModule)
            },
            {
                path: 'systemRepository',
                loadChildren: () => import('./module/system-repository/system-repository.module')
                    .then(m => m.systemRepositoryModule)
            },
            {
                path: 'systemRole',
                loadChildren: () => import('./module/system-role/system-role.module')
                    .then(m => m.systemRoleModule)
            },
            {
                path: 'systemTypeElement',
                loadChildren: () => import('./module/system-type-element/system-type-element.module')
                    .then(m => m.systemTypeElementModule)
            },
            {
                path: 'systemUser',
                loadChildren: () => import('./module/system-user/system-user.module')
                    .then(m => m.systemUserModule)
            },
            {
                path: 'systemUserStatus',
                loadChildren: () => import('./module/system-user-status/system-user-status.module')
                    .then(m => m.systemUserStatusModule)
            },
            {
                path: 'taxes',
                loadChildren: () => import('./module/taxes/taxes.module')
                    .then(m => m.taxesModule)
            },
            {
                path: 'tipeofProofXML',
                loadChildren: () => import('./module/tipeof-proof-x-m-l/tipeof-proof-x-m-l.module')
                    .then(m => m.tipeofProofXMLModule)
            },
            {
                path: 'tmp_acounting_account',
                loadChildren: () => import('./module/tmpacountingaccount/tmpacountingaccount.module')
                    .then(m => m.tmp_acounting_accountModule)
            },
            {
                path: 'turn',
                loadChildren: () => import('./module/turn/turn.module')
                    .then(m => m.turnModule)
            },
            {
                path: 'typeBeneficiary',
                loadChildren: () => import('./module/type-beneficiary/type-beneficiary.module')
                    .then(m => m.typeBeneficiaryModule)
            },
            {
                path: 'typeBeneficiaryRequest',
                loadChildren: () => import('./module/type-beneficiary-request/type-beneficiary-request.module')
                    .then(m => m.typeBeneficiaryRequestModule)
            },
            {
                path: 'typeCertificate',
                loadChildren: () => import('./module/type-certificate/type-certificate.module')
                    .then(m => m.typeCertificateModule)
            },
            {
                path: 'typeContact',
                loadChildren: () => import('./module/type-contact/type-contact.module')
                    .then(m => m.typeContactModule)
            },
            {
                path: 'typeContract',
                loadChildren: () => import('./module/type-contract/type-contract.module')
                    .then(m => m.typeContractModule)
            },
            {
                path: 'typeDisability',
                loadChildren: () => import('./module/type-disability/type-disability.module')
                    .then(m => m.typeDisabilityModule)
            },
            {
                path: 'typeEmployee',
                loadChildren: () => import('./module/type-employee/type-employee.module')
                    .then(m => m.typeEmployeeModule)
            },
            {
                path: 'typeIncome',
                loadChildren: () => import('./module/type-income/type-income.module')
                    .then(m => m.typeIncomeModule)
            },
            {
                path: 'typeMenuFood',
                loadChildren: () => import('./module/type-menu-food/type-menu-food.module')
                    .then(m => m.typeMenuFoodModule)
            },
            {
                path: 'typeNotification',
                loadChildren: () => import('./module/type-notification/type-notification.module')
                    .then(m => m.typeNotificationModule)
            },
            {
                path: 'typeOfMaintenance',
                loadChildren: () => import('./module/type-of-maintenance/type-of-maintenance.module')
                    .then(m => m.typeOfMaintenanceModule)
            },
            {
                path: 'typeOfRequisition',
                loadChildren: () => import('./module/type-of-requisition/type-of-requisition.module')
                    .then(m => m.typeOfRequisitionModule)
            },
            {
                path: 'typeOfTurn',
                loadChildren: () => import('./module/type-of-turn/type-of-turn.module')
                    .then(m => m.typeOfTurnModule)
            },
            {
                path: 'typeOffice',
                loadChildren: () => import('./module/type-office/type-office.module')
                    .then(m => m.typeOfficeModule)
            },
            {
                path: 'typePayroll',
                loadChildren: () => import('./module/type-payroll/type-payroll.module')
                    .then(m => m.typePayrollModule)
            },
            {
                path: 'typeProvider',
                loadChildren: () => import('./module/type-provider/type-provider.module')
                    .then(m => m.typeProviderModule)
            },
            {
                path: 'typeService',
                loadChildren: () => import('./module/type-service/type-service.module')
                    .then(m => m.typeServiceModule)
            },
            {
                path: 'typeTurn',
                loadChildren: () => import('./module/type-turn/type-turn.module')
                    .then(m => m.typeTurnModule)
            },
            {
                path: 'typeVobo',
                loadChildren: () => import('./module/type-vobo/type-vobo.module')
                    .then(m => m.typeVoboModule)
            },
            {
                path: 'typeofNotification',
                loadChildren: () => import('./module/typeof-notification/typeof-notification.module')
                    .then(m => m.typeofNotificationModule)
            },
            {
                path: 'typeofOffice',
                loadChildren: () => import('./module/typeof-office/typeof-office.module')
                    .then(m => m.typeofOfficeModule)
            },
            {
                path: 'uniform',
                loadChildren: () => import('./module/uniform/uniform.module')
                    .then(m => m.uniformModule)
            },
            {
                path: 'unitMeasurement',
                loadChildren: () => import('./module/unit-measurement/unit-measurement.module')
                    .then(m => m.unitMeasurementModule)
            },
            {
                path: 'useCFDI',
                loadChildren: () => import('./module/use-c-f-d-i/use-c-f-d-i.module')
                    .then(m => m.useCFDIModule)
            },
            {
                path: 'useOfCFDI',
                loadChildren: () => import('./module/use-of-c-f-d-i/use-of-c-f-d-i.module')
                    .then(m => m.useOfCFDIModule)
            },
            {
                path: 'vacationPeriodDays',
                loadChildren: () => import('./module/vacation-period-days/vacation-period-days.module')
                    .then(m => m.vacationPeriodDaysModule)
            },
            {
                path: 'vacationStatus',
                loadChildren: () => import('./module/vacation-status/vacation-status.module')
                    .then(m => m.vacationStatusModule)
            },
            {
                path: 'voboLog',
                loadChildren: () => import('./module/vobo-log/vobo-log.module')
                    .then(m => m.voboLogModule)
            },
            {
                path: 'voboProcess',
                loadChildren: () => import('./module/vobo-process/vobo-process.module')
                    .then(m => m.voboProcessModule)
            },
            {
                path: 'voboStatus',
                loadChildren: () => import('./module/vobo-status/vobo-status.module')
                    .then(m => m.voboStatusModule)
            },
            {
                path: 'warehouse',
                loadChildren: () => import('./module/warehouse/warehouse.module')
                    .then(m => m.warehouseModule)
            },
            {
                path: 'warehouseProduct',
                loadChildren: () => import('./module/warehouse-product/warehouse-product.module')
                    .then(m => m.warehouseProductModule)
            },
            {
                path: 'waste',
                loadChildren: () => import('./module/waste/waste.module')
                    .then(m => m.wasteModule)
            },
            {
                path: 'week',
                loadChildren: () => import('./module/week/week.module')
                    .then(m => m.weekModule)
            },
            {
                path: 'year',
                loadChildren: () => import('./module/year/year.module')
                    .then(m => m.yearModule)
            },
        ]
    },
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                loadChildren: () => import('./module/login/login.module').then(module => module.LoginModule)
            },
            {
                path: 'auth/reset-password',
                loadChildren: () => import('./module/auth-reset-password/auth-reset-password.module')
                    .then(module => module.AuthResetPasswordModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
